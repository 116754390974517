<template>
  <div class="loginbox">
    <div class="container">
      <form>
        <h2 class="mb-3">Login</h2>
        <div class="input">
          <label for="username">username</label>
          <input v-model="username" class="form-control" type="text" name="text" placeholder="username" />
        </div>
        <div class="input">
          <label for="password">Password</label>
          <input v-model="password" class="form-control" type="password" name="password" placeholder="password" />
        </div>

        <div class="input">
          <label for="areaName">AreaName</label>
          <input v-model="areaName" class="form-control" type="areaname" name="areaname" placeholder="areaName" />
        </div>

        <button type="submit" class="mt-4 btn-pers btn-login" id="login_button" :disabled="!validateForm"
          @click="loginJWT">
          Login
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '@/pages/services/AuthenticationService'



export default {
  data() {
    return {
      username: '',
      password: '',
      areaName: '',
      checkbox_remember_me: false
    }
  },
  components: {

  },
  computed: {
    validateForm() {
      return this.username != '' && this.password != '';
    },
  },
  methods: {

    async loginJWT() {



      let obj = {
        username: this.username,
        password: this.password,
        areaName: this.areaName
      };
      const response = await AuthenticationService.loginAdmin(obj)
      if (response.data.success == true) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('accessToken', response.data.accessToken);



        this.$router.push('/areaselect').catch(() => { })
      } else {
        this.$vs.notification({
          title: 'Error',
          text: response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      }
    },

  }
}

</script>
<style scoped>
.btn-login {
  background-color: #1d025a !important;
  width: 200px;
  border-radius: 10px;
  color: white;
  align-items: center;
  position: relative;
  right: -18%;
  height: 50px;
}

.loginbox {
  margin-top: 15%;
  margin-left: 10px;
  border: #ffffff;
  background: #070016;
  border-radius: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: auto;

}
</style>
