<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36" />
        <sidebar-link to="/dashboardold" :name="$t('Dashboard Old ')" icon="tim-icons icon-chart-pie-36" />
        <!-- <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom" /> -->
        <!-- <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin" /> -->
        <!-- <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55" /> -->
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02" />
        <sidebar-link to="/list" :name="$t('List Profile')" icon="tim-icons icon-single-02" />
        <!-- <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10" /> -->
        <!-- <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center" /> -->
        <!-- <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world" /> -->
        <sidebar-link to="/verify" :name="$t('Verify')" icon="tim-icons icon-badge" />
        <sidebar-link to="/editbet" :name="$t('Editbet')" icon="tim-icons icon-alert-circle-exc" />
        <sidebar-link to="/deposit" :name="$t('Deposit')" icon="tim-icons icon-wallet-43" />
        <sidebar-link to="/withdraw" :name="$t('Withdraw')" icon="tim-icons icon-wallet-43" />
        <sidebar-link to="/commission" :name="$t('Check Commission')" icon="tim-icons icon-coins" />
        <sidebar-link to="/setting" :name="$t('Setting')" icon="tim-icons icon-html5" />
        <sidebar-link to="/marketing" :name="$t('Marketing')" icon="tim-icons icon-puzzle-10" />
        <sidebar-link to="/trade" :name="$t('Trade')" icon="tim-icons icon-money-coins" />
        <sidebar-link to="/createcom" :name="$t('CreateCom')" icon="tim-icons icon-coins" />

      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
