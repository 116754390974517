import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import Login from "@/pages/LoginJWT.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Area = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Area.vue");
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Dashboardold = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboardold.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Verify = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Verify.vue");
const Editbet = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Editbet.vue");
const Withdraw = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Withdraw.vue");
const Deposit = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Deposit.vue");
const Volume = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Volume.vue");
const Commission = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Commission.vue");
const Setting = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Setting.vue");
const Marketing = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Marketing.vue");
const Trade = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Trade.vue");
const CommissionCreate = () =>
    import(/* webpackChunkName: "common" */ "@/pages/CreateCom.vue");
const List = () =>
    import(/* webpackChunkName: "common" */ "@/pages/List.vue"); 
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/goldentplus",
    name: "login",
    component: Login,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
  {
    path: "/DashboardLayout",
    component: DashboardLayout,
    children: [
      {
        path: "/areaselect",
        name: "area",
        component: Area,
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/dashboardold",
        name: "dashboardold",
        component: Dashboardold,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "/icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "/maps",
        name: "maps",
        component: Maps,
      },
      {
        path: "/typography",
        name: "typography",
        component: Typography,
      },
      {
        path: "/table-list",
        name: "table-list",
        component: TableList,
      },
      {
        path: "/verify",
        name: "verify",
        component: Verify,
      },
      {
        path: "/editbet",
        name: "editbet",
        component: Editbet,
      },
      {
        path: "/deposit",
        name: "deposit",
        component: Deposit,
      },
      {
        path: "/withdraw",
        name: "withdraw",
        component: Withdraw,
      },
      {
        path: "/volume",
        name: "volume",
        component: Volume,
      },
      {
        path: "/commission",
        name: "commission",
        component: Commission,
      },
      {
        path: "/setting",
        name: "setting",
        component: Setting,
      },
      {
        path: "/marketing",
        name: "marketing",
        component: Marketing,
      },
      {
        path: "/trade",
        name: "trade",
        component: Trade,
      },
      {
        path: "/createcom",
        name: "Commissioncreate",
        component: CommissionCreate
      },
      {
        path: "/list",
        name: "List",
        component: List
      }
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
